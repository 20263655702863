import React, { Fragment, useState } from "react";
import Header from "./Header";
import NavHader from "./NavHader";
import SideBar from "./SideBar";
// import ChatBox from "../ChatBox";

interface JobieProp {
  title?: string;
  ClickToAddEvent?: () => void;
}

// const JobieNav = ({ title, onClick: ClickToAddEvent }) => {
const JobieNav: React.FC<JobieProp> = ({
  title, ClickToAddEvent
}) => {
  const [toggle, setToggle] = useState("");
  const onClick = (name: string) => setToggle(toggle === name ? "" : name);
  return (
    <Fragment>
      {/* <div className="py-5 bg-primary"></div> */}
      <NavHader />
      {/* <ChatBox onClick={() => onClick("chatbox")} toggle={toggle} /> */}
      <Header
        onNote={() => onClick("chatbox")}
        onNotification={() => onClick("notification")}
        onProfile={() => onClick("profile")}
        toggle={toggle}
        title={title}
        onBox={() => onClick("box")}
        onClick={ClickToAddEvent}
      />
      <SideBar />
    </Fragment>
  );
};

export default JobieNav;
