import React, { memo, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { formatPrice } from "../../../../until";
interface Prop {
	dataChart: any[]
	dataCho: string[]
}
const ChartBarApex: React.FC<Prop> = ({ dataChart, dataCho }) => {

	const [state, setState] = useState<any>(
		{
			series: [
				{
					name: 'Tổng thu',
					data: [],
					//radius: 12,	
				},
				{
					name: 'Tổng nợ',
					data: []
				},
			],
			options: {
				chart: {
					type: 'bar',
					height: 370,
					toolbar: {
						show: false,
					},
				},
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: '57%',
						endingShape: "rounded",
						borderRadius: 12,
					},
				},
				states: {
					hover: {
						filter: 'none',
					}
				},
				colors: ['#D2D2D2', '#EBEBEB'],
				//colors:['var(--primary)'],
				dataLabels: {
					enabled: false,
				},
				markers: {
					shape: "circle",
				},
				legend: {
					show: false,
					fontSize: '12px',
					labels: {
						colors: '#000000',

					},
					markers: {
						width: 18,
						height: 18,
						strokeWidth: 10,
						strokeColor: '#fff',
						fillColors: undefined,
						radius: 12,
					}
				},
				stroke: {
					show: true,
					width: 4,
					curve: 'smooth',
					lineCap: 'round',
					colors: ['transparent']
				},
				grid: {
					borderColor: '#eee',
				},
				xaxis: {
					position: 'top',
					categories: ['Đơn vị Mới Bình Thuận', 'Đơn vị Cẩm lệ', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
					labels: {
						style: {
							colors: '#787878',
							fontSize: '13px',
							fontFamily: 'poppins',
							fontWeight: 100,
							cssClass: 'apexcharts-xaxis-label',
						},
					},
					crosshairs: {
						show: false,
					}
				},
				yaxis: {
					labels: {
						offsetX: -16,
						style: {
							colors: '#787878',
							fontSize: '13px',
							fontFamily: 'poppins',
							fontWeight: 100,
							cssClass: 'apexcharts-xaxis-label',
						},
					},
				},
				fill: {
					opacity: 1,
					colors: ['var(--primary)', '#FD5353'],
				},
				tooltip: {
					y: {
						formatter: function (val: any) {
							return formatPrice(val) + " VNĐ"
						}
					}
				},

			},
		}
	)
	useEffect(() => {
		setState({
			series: dataChart,
			options: {
				chart: {
					type: 'bar',
					height: 370,
					toolbar: {
						show: false,
					},
				},
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: '57%',
						endingShape: "rounded",
						borderRadius: 12,
					},
				},
				states: {
					hover: {
						filter: 'none',
					}
				},
				colors: ['#D2D2D2', '#EBEBEB'],
				//colors:['var(--primary)'],
				dataLabels: {
					enabled: false,
				},
				markers: {
					shape: "circle",
				},
				legend: {
					show: false,
					fontSize: '12px',
					labels: {
						colors: '#000000',

					},
					markers: {
						width: 18,
						height: 18,
						strokeWidth: 10,
						strokeColor: '#fff',
						fillColors: undefined,
						radius: 12,
					}
				},
				stroke: {
					show: true,
					width: 4,
					curve: 'smooth',
					lineCap: 'round',
					colors: ['transparent']
				},
				grid: {
					borderColor: '#eee',
				},
				xaxis: {
					position: 'bottom',
					categories: dataCho,
					labels: {
						style: {
							colors: '#787878',
							fontSize: '13px',
							fontFamily: 'poppins',
							fontWeight: 100,
							cssClass: 'apexcharts-xaxis-label',
						},
					},
					crosshairs: {
						show: false,
					}
				},
				yaxis: {
					labels: {
						offsetX: -16,
						style: {
							colors: '#787878',
							fontSize: '13px',
							fontFamily: 'poppins',
							fontWeight: 100,
							cssClass: 'apexcharts-xaxis-label',
						},
					},
				},
				fill: {
					opacity: 1,
					colors: ['var(--primary)', '#FD5353'],
				},
				tooltip: {
					y: {
						formatter: function (val: any) {
							return formatPrice(val) + " VNĐ"
						}
					}
				},

			},
		})
	}, [dataChart, dataCho])
	return (
		<div id="chart" >
			<ReactApexChart
				options={state?.options}
				series={state?.series}
				type="bar"
				height={440}
			/>
		</div>
	);
}

export default memo(ChartBarApex);