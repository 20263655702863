import React, { Fragment, ReactNode } from "react";
// BS
interface ButtonLoadingProps {
    type?: "button" | "submit" | "reset";
    className?: string;
    children: ReactNode;
    loading?: boolean;
    onClick?: (e?: any) => void;
    disabled?: boolean;
}
const ButtonLoading: React.FC<ButtonLoadingProps> = ({ type, className, children, loading = false, onClick, disabled = false }) => {
    return (
        <Fragment>
            {loading ?
                <button
                    type={type}
                    className={className}
                    disabled={disabled}
                >
                    <div className="text-center"><i className="fad fa-spinner-third fa-spin"></i></div>
                </button>
                :
                <button
                    onClick={onClick}
                    type={type}
                    className={className}
                    disabled={disabled}
                >   {
                        <div className="text-center">{children}</div>
                    }
                </button>
            }

        </Fragment>
    );
};

export default ButtonLoading;
